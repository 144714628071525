.single {
    display: flex;
  }
  
  /* Responsive CSS for mobile */
  @media (max-width: 767px) {
    .single {
      flex-direction: column;
    }
  }
  