.header {
  margin-top: 60px;
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lora", serif;
  color: #444;
}

.headerTitleSm {
  position: absolute;
  top: 18%;
  font-size: 20px;
}

.headerTitleLg {
  position: absolute;
  top: 20%;
  font-size: 100px;
}

.headerImg {
  width: 100%;
  height: 450px;
  margin-top: 80px;
  object-fit: cover;
}

/* Responsive CSS for mobile */

@media (max-width: 767px) {
  .header {
    margin-top: 40px;
  }

  .headerTitles {
    text-align: center;
  }

  .headerTitleSm {
    position: relative;
    top: 0;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .headerTitleLg {
    position: relative;
    top: 0;
    font-size: 60px;
    margin-bottom: 20px;
  }

  .headerImg {
    height: 300px;
    margin-top: 40px;
  }
}
