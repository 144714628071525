.singlePost {
  flex: 9;
}

.singlePostWrapper {
  padding: 20px;
  padding-right: 0;
}

.singlePostImg {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  object-fit: cover;
}

.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-size: 28px;
  font-family: "Lora", sans-serif;
}

.singlePostEdit {
  float: right;
  font-size: 16px;
}

.singlePostIcon {
  margin-left: 10px;
  cursor: pointer;
}

.singlePostIcon:first-child {
  color: teal;
}

.singlePostIcon:last-child {
  color: tomato;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #be9656;
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
}

.singlePostAuthor {
  margin-left: 5px;
}

.singlePostDesc {
  color: #666;
  font-size: 18px;
  line-height: 25px;
}

.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

/* Responsive CSS for mobile */

@media (max-width: 767px) {
  .singlePostWrapper {
    padding: 10px;
  }

  .singlePostImg {
    height: 200px;
  }

  .singlePostTitle {
    font-size: 24px;
  }

  .singlePostEdit {
    font-size: 14px;
  }

  .singlePostIcon {
    margin-left: 8px;
  }

  .singlePostInfo {
    font-size: 14px;
  }

  .singlePostDesc {
    font-size: 16px;
    line-height: 22px;
  }

  .singlePostDesc::first-letter {
    margin-left: 10px;
    font-size: 26px;
  }
}
