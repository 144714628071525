.home {
  display: flex;
}

/* Responsive CSS for mobile */
@media (max-width: 767px) {
  .home {
    flex-direction: column;
  }
}
