.top {
  width: 100%;
  height: 50px;
  background-color: white;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  font-family: "Josefin Sans", sans-serif;
}

.topLeft,
.topRight {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topIcon {
  font-size: 20px;
  margin-right: 10px;
  color: #444;
  cursor: pointer;
}

.topCenter {
  flex: 6;
}

.topList {
  display: flex;
  margin: 0;
  padding: 20px;
  list-style: none;
  
}

.topListItem {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

.topListItem:hover {
  color: gray;
}

.topImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
 
  cursor: pointer;
}



/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .top {
    height: auto;
    padding: 10px;
    
  }

  .topLeft,
  .topRight {
    flex: 1;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .topCenter {
    flex: 2;
  }

  .topList {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
  }

  .topListItem {
    margin: 5px 0;
  }

  .topImg {
    margin-right: 5px;
    margin-left: 100px;
  }
}
